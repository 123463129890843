<template>
  <div
    style="
      max-width: 600px;
      margin: auto;
      justify-content: center;
      display: flex;
      margin-top: 10rem;
      margin-bottom: 5rem;
    "
  >
    <v-container class="pb-0">
      <v-row style="margin: 0rem 0rem 1rem 0rem">
        <h2>Inicio de Sesión</h2>
      </v-row>
      <div style="display: block; justify-content: center; margin: auto">
        <div cols="4" class="py-0 px-1">
          <o-email-field style="font-family: montserrat" v-model="user.email" />
        </div>
        <div cols="4" class="py-0 px-1">
          <o-password-field
            style="font-family: montserrat"
            v-model="user.password"
          />
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <v-btn
            style="font-family: montserrat; width: 100%"
            color="secondary"
            @click="signIn"
          >
            Continuar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <div
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            margin: auto;
          "
        >
          <div>
            <p>
              No tienes cuenta? Regístrate
              <a
                style="font-weight: bold; cursor: pointer"
                :href="linkRegistro"
              >
                aquí</a
              >
            </p>
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "SignIn",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loading: false,
      linkRegistro: "https://test.higherupcoaching.com/registro",
    };
  },
  computed: {
    ...mapGetters(["isUserAuthenticated"]),
  },
  methods: {
    validateForm() {
      if (!this.user.email || !this.user.password) {
        this.errorDialog("Todos los campos son obligatorios.");
        return false;
      }
      return true;
    },
    async signIn() {
      if (this.validateForm()) {
        const user_account = {
          email: this.user.email,
          password: this.user.password,
        };
        console.log(user_account);
        this.loading = true;
        this.$store
          .dispatch("signUserIn", user_account)
          .then(() => {
            this.loading = false;
            this.$router.push("/");
          })
          .catch((error) => {
            this.loading = false;
            this.errorDialog(error.message);
          });
      }
    },
    goToForgotPassword() {
      this.$router.push("/forgot-password");
    },
  },
  async mounted() {
    if (this.isUserAuthenticated) {
      this.$router.push("/");
    }
  },
});
</script>

<style scoped>
.forgot-password-button:hover {
  text-decoration: underline;
}
</style>
