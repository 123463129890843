import axios from "axios";

const firebaseEndpoint = "https://apitest.higherupcoaching.com/";

export default {
  async sendOrderConfirmation(order) {
    try {
      console.log(order);
      let response = await axios.get(
        firebaseEndpoint + "orders-testing",
        order
      );
      console.log(response.data);
      return await response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },

};
